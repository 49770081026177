/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .imgContainer {
    width: 100%;
  }

  .textContainer {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .imgContainer {
    width: 100%;
    height: 65rem;
  }

  .textContainer {
    background-color: rgba(0, 0, 0, 0.6);
    height: 65rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .imgContainer {
    width: 100%;
    height:64rem;
  }

  .textContainer {
    background-color: rgba(0, 0, 0, 0.6);
    height: 64rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .imgContainer {
    width: 100%;
    height: 64rem;
  }

  .textContainer {
    background-color: rgba(0, 0, 0, 0.6);
    height: 64rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .imgContainer {
    width: 100%;
    height: 64rem;
  }

  .textContainer {
    background-color: rgba(0, 0, 0, 0.6);
    height: 64rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
  .imgContainer {
    width: 100%;
    height: 130vh;
  }

  .textContainer {
    background-color: rgba(0, 0, 0, 0.6);
    height: 130vh;
  }
}
